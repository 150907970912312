.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #282c34;
}

.result-span {
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
  padding: 0.5rem 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
