.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 5px 0;
  border-radius: 10px;
  position: relative;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 5px 16px 45px 16px;
}

.percent {
  padding: 2px 30px 0 16px;
  display: "flex";
}
